.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.pick-class-label {
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.google-maps-section {
  height: auto !important;
}

.margin-2em {
  margin: 2em;
}

.bottom-button {
  position: absolute;
  bottom: 0;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #ffffff;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #ffffff;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

/* custom classes start */
.pp-seperator-line {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #2ca8ff;
  width: 100px;
  /*margin: 0 auto;*/
  display: block;
}

.trust-icons {
  font-size: 60px;
  margin: 3em;
}
/* custom classes end */

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

.fixed-section ul li {
  list-style: none;
}

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

.specialties-card {
  padding-left: 1.7em;
  padding-right: 1.7em;
  padding-bottom: 1.5em;

  height: auto;
}

.disclosures {
  column-count: 1;
}

.specialties-card .card-body {
  padding: 0em;
}

.reported-decisions {
  height: 205vh !important;
  max-height: 205vh !important;
}

.specialties-section {
  height: 700vh !important;
  max-height: 800vh !important;
}

.expertiseContainer .card {
  width: 19em;
  height: 17em;
}
.expertiseContainer .card:hover {
  cursor: pointer;
  bottom: 1em;
}

.section-disclosures {
  padding: 6em !important;
}

.svgContainer {
  height: 115vh;
  width: 100%;
  /*background: url(../images/abstract2.jpg) no-repeat;*/
  background-size: cover;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  z-index: 3;
}

.svgTop {
  /*background: url(../images/vectors/perspectiveTop.svg) no-repeat top;*/
  width: 100%;
  height: 100%;
  top: -2px;
  background-size: 101% auto;
  z-index: 1;
  position: absolute;
  transform: translateZ(0);
}
.linkStyle a {
  color: #007bff;
  font-weight: 700;
}

.decisionsStyle h4 {
  margin-top: 0em;
}
.decisionsStyle {
  padding-top: 0em !important;
}

.reported-decision-md {
  height: auto;
  color: black;
}
.reported-decision-sm {
  height: auto;
  color: black;
}

.reported-decision-xs {
  height: auto;
  color: black;
}

#navbar-brand {
  font-size: 24px;
}

#navbar-brand-pn {
  font-size: 20px;
}
.svgBottom {
  /*background: url(../images/vectors/perspectiveBottom.svg) no-repeat bottom;*/
  width: 100%;
  height: 115vh;
  bottom: -2px;
  background-size: 101% auto;
  z-index: 1;
  position: absolute;
  transform: translateZ(0);
  /*-webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);*/
}

.footerLeft,
.footerRight {
  display: flex;
  justify-content: center;
}

.useful-links {
  height: 113vh !important;
}

.clients-row {
  padding: 1.5em;
}

.expertiseContainer {
  margin-top: 35%;
}

.page-header .container {
  padding-bottom: 8em;
}

.section {
  padding: 2em;
}

/* small phones */
@media screen and (min-width: 320px) {
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 75vh;
  }
}

/* smallest */
@media only screen and (min-device-width: 320px) and (max-device-height: 775px) {
  .svContainer,
  .svgTop,
  .svgBottom {
    /*height: 85vh !important;*/
    height: 560px;
  }
}

.pressSection {
  height: 100vh;
}

.pressText {
  padding-left: 15px;
  padding-right: 15px;
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 700px) and (-webkit-device-pixel-ratio: 2) {
}
/*iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .svContainer,
  .svgTop,
  .svgBottom {
    /*height: 88vh !important;*/
    height: 600px !important;
  }
}

@media only screen and (min-device-width: 370px) and (max-device-height: 900px) {
  .specialties-section {
    height: 446vh !important;
    max-height: 446vh !important;
  }
}

/* phones */
@media screen and (min-width: 400px) {
  .svContainer,
  .svgTop,
  .svgBottom {
    /*height: 75vh;*/
    height: 600px !important;
  }

  .specialties-section {
    height: 380vh;
    max-height: 380vh;
  }
}

/* phones */
@media screen and (min-width: 600px) {
}

/* large phones, tables */
@media screen and (min-width: 768px) {
  .expertiseContainer {
    margin-top: 25%;
  }
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 75vh !important;
  }

  .specialties-card {
    height: 35em;
  }
  .specialties-section {
    height: 440em !important;
    max-height: 440vh !important;
  }

  .reported-decisions {
    height: 180vh !important;
    max-height: 300vh !important;
  }

  .clients-padding {
    padding-left: 15%;
    padding-right: 15%;
  }

  .clients-row {
    padding: 4em;
  }

  .useful-links {
    height: 107vh !important;
  }
}

/* smaller desktops */
@media screen and (min-width: 960px) {
  .expertiseContainer {
    margin-top: 20%;
  }
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 84vh !important;
  }
  .specialties-card {
    height: 42em;
  }

  .disclosures {
    column-count: 2;
    column-gap: 3em;
  }
  .pressText {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* smaller desktops */
@media screen and (min-width: 992px) {
  .google-maps-section {
    height: 105vh !important;
  }
  .reported-decisions {
    height: 115vh !important;
    max-height: 300vh !important;
  }
  .reported-decision-md {
    height: 10em;
    color: black;
  }
  .reported-decision-sm {
    height: 4em;
    color: black;
  }

  .reported-decision-xs {
    height: 2em;
    color: black;
  }
  .specialties-section {
    height: 270em !important;
    max-height: 270vh !important;
  }
  .footerLeft {
    justify-content: left;
  }
  .footerRight {
    justify-content: flex-end;
  }
  .pressSection {
    height: 50vh;
  }
}

#navbar-rkd {
  padding-top: 0px !important;
}
.rkd {
  display: block;
  width: 275px;
  word-wrap: break-word;
  white-space: normal;
  /* line-height: 1.25em; */
  font-size: 1.5em;
}

/* larger desktops */
@media screen and (min-width: 1200px) {
  .expertiseContainer {
    margin-top: 10%;
  }
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 110vh !important;
  }
  .rkd {
    font-size: 2.15em;
    margin-bottom: 0.25em;
    display: block;
    width: 350px;
    word-wrap: break-word;
    white-space: normal;
    line-height: 1.25em;
  }

  #navbar-rkd {
    padding-top: 10px !important;
  }

  .specialties-card {
    height: 36em;
  }

  .reported-decisions {
    height: 115vh !important;
    max-height: 300vh !important;
  }
  .useful-links {
    height: 90vh !important;
    min-height: 90vh;
  }
  .specialties-section {
    height: 205em !important;
    max-height: 205vh !important;
  }

  .footerRow {
    padding-left: 5em;
    padding-right: 5em;
  }
}

@media screen and (min-width: 1300px) {
  .expertiseContainer {
    margin-top: 10%;
  }
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 110vh !important;
  }

  .specialties-card {
    height: 36em;
  }

  .reported-decisions {
    height: 95vh !important;
    max-height: 300vh !important;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-height: 900px) {
  .specialties-section {
    height: 385em !important;
    max-height: 385vh !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-height: 800px) {
  .svContainer,
  .svgTop,
  .svgBottom {
    height: 135vh !important;
  }
  .useful-links {
    height: 100vh !important;
  }
  .reported-decisions {
    height: 115vh !important;
    max-height: 300vh !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-height: 900px) {
  .specialties-section {
    height: 215em !important;
    max-height: 215vh !important;
  }
}

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

.lawyer-link {
  color: inherit !important;
  text-decoration: none !important;
}

.title-main,
.description {
  text-align: left;
}

@media (min-width: 1000px) {
  #navbar-brand {
    font-size: 30px;
  }
  .header-title {
    line-height: 1.6;
    padding: 10%;
    text-align: left;
  }
}
.btn-lg {
  font-weight: 600;
}

.slick-prev:before {
  display: none !important;
}
.slick-next:before {
  display: none !important;
}

.imagePadding {
  margin-bottom: 15px !important;
}

.imageRightPadding {
  margin-left: 0em;
}

@media (min-width: 768px) {
  .team-player {
    padding: 1.5em;
  }
  .title-main,
  .description {
    text-align: center;
  }
  .pp-seperator-line {
    margin: 0 auto;
  }

  .slick-list {
    height: 100%;
  }

  .imageRightPadding {
    margin-left: 3em !important;
  }

  .slick-prev:before {
    font-family: "Font Awesome 5 Free" !important;
    content: "\f104" !important;
    display: inline-block !important;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;

    color: #2ca8ff !important;
    font-size: 30px !important;
  }

  .slick-next:before {
    font-family: "Font Awesome 5 Free" !important;
    content: "\f105" !important;
    display: inline-block !important;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;

    color: #2ca8ff !important;
    font-size: 30px !important;
  }
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
  .header-title {
    text-align: left;
    font-size: 2.5em;
    line-height: 1.4;
  }
}

@media (max-width: 400px) {
  .btn-lg {
    padding: 14px 42px;
  }
}

/*       Loading dots  */

/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.required-field::before {
  content: "*";
  color: red;
  margin-left: 2px;
}

#root {
  overflow-x: hidden !important;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #ffffff;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.section-tabs {
  background: #eeeeee;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a + a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper > .header,
.tutorial-page .wrapper > .header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.components-page .title,
.tutorial-page .title {
  color: #ffffff;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

.section-signup {
  padding-top: 2vh;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}

.modal-body p {
  font-weight: 500;
}

.modal .modal-header .close {
  color: #2ca8ff;
  text-shadow: none;
}

.alert {
  padding: 20px;
  background-color: #2ca8ff;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.clean-link,
.clean-link:hover,
.clean-link:active,
.clean-link:visited {
  text-decoration: none;
  color: white;
  background-color: transparent;
}
